.subs-outer-div{
    /* width: 70%; */
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 15px;
    }
    .subs-outer-div p{
        font-size: 12px;
    }
    /* .subs-inner-div{
    display: flex;

    align-items: center;
    gap: 20px;
    } */
    .subs-img-div{
    /* width: 150px; */
    
    }
    .subs-img-div img{
        width: 150px;
        aspect-ratio: 1/1;
    }
    .subs-p-div{
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .subs-button-div{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .subs-button{
        padding: 10px;
        background-color: #c7a17a;
        border: none;
        border-radius: 5px;
        color: white;

    }

    .dia-outer-div{
        display: flex;
        padding: 40px 40px 0px 40px;
        gap: 40px;
        background-color: #fff;
        font-family: sans-serif;
    }
    .dia-img-div{
        width:40%;
        display: flex;
        align-items: center;
    }
    .dia-img-div img{
        object-fit: contain;
        width: 100px;
    }
    .dia-outer-div p{
        line-height: 20px;
    }
    .dia-p-div{
        background-color: #fff;

    }
    svg{
        font-size: "20px";
    }

    @media screen and (max-width: 768px) {
        .subs-outer-div{
            width: 100%;
            }
    }

    @media screen and (max-width: 425px) {
        .dia-img-div{
            width:75%;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }
        .dia-outer-div{
            display: block;
            padding: 40px 40px 0px 40px;
            gap: 40px;
            background-color: #f6f6f6;
            font-family: sans-serif;
        }
    }