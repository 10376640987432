.mainDivHader{
   
    padding: 10px;
    position: fixed;
    width: 100%;
    background:#000;
    transition: background-color 0.5s ease 0s;
    z-index: 9;
    top:0px;
    border-bottom: 2px solid #fff;
}
.linksDiv{
    display: flex;
    justify-content: space-around;
    /* gap: 45px; */
}
.landingModal{
  height: 500px;
  background-image: url("./assets/img/open_hour_bg.jpg");
  background-position: center;
  background-size: cover;
  font-family: Arial, Helvetica, sans-serif;
  z-index: 99999999999999;
}
.offerTitle{
    /* font-size: 47px; */
    font-weight: 600;
   
}
.descriptionModal{
    font-size: 17px;
    font-family: none;
    line-height: 30px;
}
.shopLink{
    font-size: 20px;
    font-weight: 500;
    color: #63503d;
    text-decoration: none;
}

.modal-body{
    padding-bottom: 50px !important;
}
.modal-content{
    background-color: #e9e7e6   !important;
}
.haderLink{
    font-size: 18px;
    font-weight: 600;
    /* color: #000 !important; */
    text-decoration: none;
}
.searchIcon{
    /* color: #fff; */
    cursor: pointer;
}
.haderLogo{
    height: 100px;
    width: 100px;
    background: #000;
    border-radius: 56px;
}
.logoDiv{
    display: flex;
    justify-content: center;
}
.haderRow{
    align-items: center;
}
.cart_counter {
    background-color: #f1c232;
    border-radius: 40px;
    color: black;
    font-weight: bold;
    font-size: 12px;
    height: 20px;
    line-height: 22px;
    min-width: 22px;
    position: absolute;
    /* right: 7%; */
    text-align: center;
    top: 35px;
}
.dropdown-container {
    position: relative;
    display: inline-block;
  
  }
  .userBtn{
    border: 1px solid ;
    border-radius: 7px;
    padding:  15px;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    z-index: 1;
    margin-top: 10px;
  }
  
  .dropdown-container:hover .dropdown-menu {
    display: block;
    right: -35px;
    padding: 10px;
    top: 40px;
    margin-top: 10px;

  }

  .hoverLink{
    text-decoration: none;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
 
  }
  .hoverLink:hover{
    color: #f1c232;
  }
  .bannerImg{
    background-size: cover;
    background-position: center;
  }
  .descriptionDiv{
    position: absolute;
    top: 70px;
    left: 150px;
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .descriptionTitle{
    color: #fff;
    font-size: 30px;
 
  }
  .descriptionSubTitle{
    color: #fff;
    font-size: 50px;
 
    margin-bottom: 20px;
  }
  .description{
    color: #fff;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .descriptionDiv a{
    text-decoration: none;
    color: #fff;
  }
  a{
    text-decoration: none;
  }

  .slider-divv{
    height: 100vh;
    position: relative; 
    overflow: hidden;
  }
  .slider-divv video{
    height: 100%;
    width: 100%;
    object-fit: cover; 
    position: absolute; 
    top: 0; 
    left: 0;
  }
  /* .cart_btn{
    border: none !important;
    background: #fff !important;
    color: black !important;
    font-size: 20px !important;
  } */
  .cart_btn1{
    font-size: 20px;
    color: #fff;
    background-color: #000;
    border: none;
    padding:15px
  }

  .carousel-control-prev-icon, .carousel-control-next-icon {
    visibility: hidden !important;
  }
  
  .carousel-control-prev:hover .carousel-control-prev-icon {
    visibility: visible !important;
  }
  .carousel-control-next:hover .carousel-control-next-icon {
    visibility: visible !important;
  }