.loyalityCard{
    padding: 30px;
    height: 250px !important;
    /* box-shadow: 2px 2px 3px gray; */
}
.loyalitytitle{
    /* text-align: center; */
}
.loyalityCard:hover{
    background-blend-mode: #291507;
    opacity: 0.5;
    color: #050505;
    transition:  0.5s;
}
.userIcon{
    font-size: 30px;
}
.loyalityContainer{
    padding: 80px 0px;
}
.viewBtnDiv{
    display: flex;
    justify-content: center;
    margin-top: 40px !important;
}
.viewBtnDiv a{
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 7px;
    color: #000;
    font-weight: 500;
}
.viewBtnDiv a:hover{
    background-color: #000;
    color: #fff;
}
