
.reletedTitle{
    margin-top: 30px;
  }

  .shop_card {
    padding: 15px;
    margin-top: 30px;
    position: relative;
    border-radius: 5px;
    background-color: #ffffff;
    transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    box-shadow: 0px 1px 22px 0px rgba(211, 211, 211, 0.25);
  }

  .item_title_related {
    display: block;
    font-size: 18px;
    font-weight: 500;
    padding: 21px 25px;
    /* position: relative; */
    padding-left: 80px;
    color: #1b1b1b;
  }


  .reledCard{
    width: 90%;
    height: 90%;
  }

  @media screen and (max-width: 575px) {
    .reledCard {
        width: 100%;
    }
  }

  
  
