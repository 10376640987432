.contact_form.bg_white {
    background-color: #fff;
}
.fadeInUp {
    animation-name: fadeInUp;
}
.contact_form {
    background-color: #f6f6f6;
    padding: 60px 50px;
}
.main_contact_info_wrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px;
}
.main_contact_info_wrap .contact_info_item {
    margin-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
}
.contact_info_item {
    align-items: center;
    display: flex;
}
.contact_info_item .item_icon {
    align-items: center;
    background-color: #f6f6f6;
    border-radius: 2px;
    color: #000;
    display: inline-flex;
    font-size: 24px;
    height: 50px;
    justify-content: center;
    margin-right: 20px;
    min-width: 50px;
}
.contact_info_item .item_title {
    font-size: 15px;
    line-height: 1;
    margin-bottom: 10px;
}
.form_item {
    margin-bottom: 30px;
    position: relative;
}
.contact_form.bg_white .form_item input, .contact_form.bg_white .form_item textarea {
    background-color: #f6f6f6;
    border: 1px solid #f6f6f6;
}
.contact_form .form_item input, .contact_form .form_item textarea {
    background-color: #fff;
    border: 1px solid #fff;
}
.form_item input {
    height: 56px;
    padding: 0 25px;
}
.form_item input, .form_item textarea {
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    box-shadow: none;
    display: block;
    outline: none;
    transition: .6s cubic-bezier(.25,1,.5,1);
    width: 100%;
}
.btn.btn_primary {
    background-color: #000;
    color: #fff;
    padding: 7px 0px !important;
    width: 140px;
}
.hello{
    color: white;
}
.btn_primary:hover .hello {
        color: black;
}
.text-uppercase {
    text-transform: uppercase !important;
}
.btn {
    background-color: initial;
    border: 1px solid #0000;
    border-radius: .25rem;
    color: #212529;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    padding: .375rem .75rem;
    text-align: center;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
}
.text-uppercase {
    text-transform: uppercase !important;
}
.contact_info_item p {
    line-height: 1.4;
    margin-bottom: 0;
}