.locationCardDiv{
    padding: 25px;
    background-color: #fff !important;
    color: #000 !important;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin: 10px 0px;
}
.sec_ptb_120 .nav-link{
    color: #000 !important;
}
.sec_ptb_120 .nav-tabs .nav-link.activ{
    color: #c7a17a !important;
}