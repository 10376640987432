.blogBg {
    /* background-image: url(./../img/blogBg.0773bceed9ac2b312423.png) */
}


.sec_ptb_120, .sec_ptb_80 {
    padding-bottom: 80px;
    padding-top: 80px;
}
.text-center {
    text-align: center !important;
}
.fadeInUp {
    animation-name: fadeInUp;
    font-family: Arial, Helvetica, sans-serif;
}
.blog_grid {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 7px 15px 0 #e1e1e14d;
    margin-top: 30px;
    padding: 20px;
    position: relative;
}
.blog_grid .item_image {
    border-radius: 5px;
    display: block;
    overflow: hidden;
    position: relative;
}
.blog_grid .item_content {
    padding: 25px 5px 5px;
}
.blog_grid p {
    margin-bottom: 10px;
}
.dateSection {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    gap: 10px;
}
.blog_grid .item_title {
    display: inline-block;
    margin-bottom: 13px;
}
.blog_grid .item_title a {
    color: #1b1b1b;
    display: block;
    font-size: 20px;
}
.blog_grid p {
    margin-bottom: 10px;
}
.blog_grid .btn_text {
    color: #c7a17a;
}
.btn_text {
    color: #7c7770;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
}
.btn_text span:before {
    background-color: #c7a17a;
    bottom: 0;
    content: "";
    height: 1px;
    left: auto;
    position: absolute;
    right: 0;
    transition: .6s cubic-bezier(.25,1,.5,1);
    width: 0;
}
.overBlogSection {
    display: flex;
    justify-content: center;
    padding-top: 35px;
}
.btn.border_black {
    border-color: #12100d4d;
    color: #1b1b1b;
    margin: 15px 0;
}
.btn.btn_border {
    border: 2px solid #000;
    padding: 14px 23px;
}
.btn.btn_border:hover{
    background-color: #000;
    color: #fff;
}
a{
    text-decoration: none !important;
    color: #7c7770 ;
}
.text-uppercase {
    text-transform: uppercase !important;
}
.section_title {
    margin-bottom: 50px;
}