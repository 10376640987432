
.logo-img{
    width: 100px;
    aspect-ratio: 1/1;
    object-fit: cover;
}
.footer-down{
    display: flex;
    justify-content: space-evenly;
    background-color: #020100;
    color: white;
    padding-top: 10px;
}
.footer-outer{
    padding-top: 50px;
}
.footer-main-div{
    margin: 0 5%;
    font-family: Arial, Helvetica, sans-serif;
}
.footer-main-div p{
    cursor: pointer;
    text-transform: uppercase;
}
.footer-main-div h6{
    cursor: pointer;
    text-transform: uppercase;
}

@media (max-width:1024px){
   
}
@media (max-width:768px){
   .padding-footer{
    padding: 20px 0px;
   }
   .footer-row{
    padding: 0 !important;
   }
}
@media (max-width:425px){
    .footer-up{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background-size:cover;
        background-position: center;
        width: 90%;
        padding: 5%;
    }
    .footer-down{
        padding: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: #c8a27a;
    }
    .padding-footer{
        padding: 20px 20px;
       }
       .footer-main-div{
        margin: 0 5%;
        font-family: Arial, Helvetica, sans-serif;
    }
}