 /* 4k Screen =====> */
@media only screen and (min-width: 2560px) {
.linksDiv{

    /* gap: 152px; */
}
.cart_counter{
    right: 12%;
    top: 40px;
}
}

/* larg leptop scree =====> */

@media only screen and (min-width: 1281px) and (max-width:1440px) {
    .linksDiv{
   
        /* gap: 55px; */
    }
    .cart_counter{
        /* right: 7%; */
        top: 40px;
    }
    }

 /* small leptop screen =====>  */
 @media only screen and (max-width: 1024px) and (min-width: 767px) {
    .linksDiv{
  
        /* gap: 40px; */
        justify-content: space-evenly;
    }
    .cart_counter{ 
        right: 10%;
        top: 22px;
    }
    /* .descriptionDiv {
      
        top: 113px;
      
    } */
    .descriptionSubTitle {
        font-size: 35px;
    }
    .container-buttons button{
        padding: 11px 70px;
    }
    .col-img{
        margin-top: 30px !important;
      
    }
    .homeLinks{
        font-size: 12px !important;
    }
    }

    /* tabalte screen ===> */
    @media only screen and (max-width:768px) and (min-width: 426px) {
        /* .descriptionDiv {
            top: 165px;
           
        } */
        .descriptionDiv {
        
 
            left: 193px;
            width: 51%;
        }
        .descriptionSubTitle{
            font-size: 40px;
        }
        .descriptionTitle {
         
            font-size: 28px;
        }
        .bradCrumDiv {
            height: 400px;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            margin-top: 0px;
        }
        .col-img{
            margin-top: 30px !important;
          
        }
        }
 /* mobile screen ===> */
        @media only screen and (max-width:426px) and (min-width: 0px) {
            .descriptionSubTitle {
                font-size: 20px;
            }
            .descriptionTitle {
                font-size: 16px;
            }
            .descriptionDiv {
                left: 70px;
                width: 72%;
            }
            .about-img{
                margin-top: 0px;
            }
            .footerCol{
                padding: 20px 0px;
            }
            /* .descriptionDiv {
                top: 124px;
            } */
            .container-buttons button{
                padding: 8px 45px;
            }
            .billing-btn-div .wid {
                width: 49%;
            }
            .col-img{
                margin-top: 30px !important;
              
            }

        .bradCrumDiv{
            margin-top:0px
            }
            .section_title {
                margin-bottom: 20px;
            }
            .section_title .big_title {
                font-size: 30px;
                line-height: 1;
                margin-bottom: 0;
            }
            .sec_ptb_120 {
                padding-top: 80px;
                padding-bottom: 0px;
            }
            }