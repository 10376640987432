.product-header{
    text-align: center;
    font-size: 3vw;
    margin: 40px 0px;
    color: #0c0701;
}
.slider-div{
    height: 300px;
    position: relative;
}
.slider-img{
    height: 95%;
    width: 95%;
    object-fit: contain;
    margin: auto;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


.slider-h3{
    text-align: center;
}
.pd-tp-50{
    padding-top: 50px;
}
.pd-tp-80{
  padding-bottom: 80px;
}







.flip-container {
    position: relative;
    height: 300px;
    perspective: 1000px; /* Adjust the perspective for 3D effect */
  }
  
  .flip-card {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.5s;
  }
  
  .flip-container:hover .flip-card {
    transform: rotateY(180deg);
  }
  
  .front, .back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .front {
    background-color: white;
  }
  
  .back {
    background-color: #f8cb9a;
    transform: rotateY(180deg);
    display: none; /* Initially hidden */
  }
  .back p{
    margin: 20px;
    color: rgb(92, 91, 91);
    text-align: justify;
    line-height: 30px;
  }
  
  .flip-container:hover .back {
    display: block; /* Show the back side on hover */
  }

  .pd-50{
    /* padding: 50px 15%; */
  }
  .pro-img-hover{
    width: 100%;
    height: 100%;
    position: absolute;
    color: white;
    display: none;
  }
  .por-div-hover:hover .pro-img-hover{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.637);
    color: white;
    display: block;
    text-align: center;
    padding: 50px;
    cursor: pointer;
    opacity: 1;
  }
  .por-div-hover + h2{
    text-align: center;
  }

  @media (max-width:1024px){
   
  }
  @media (max-width:768px){
    .product-header{
      text-align: center;
      font-size: 3vw;
      margin-top: 0px;
      color: #75522d;
  }
  }
  @media (max-width:425px){
    .product-header{
      text-align: center;
      font-size: 6vw;
      margin-top: 0px;
      color: #75522d;
  }
  }
  