.loginCard{
    margin: 157px 0px;
    padding: 30px;
    width: 450px;
    box-shadow: 2px 3px 7px #000;
}
.loginDiv{
    display: flex;
    justify-content: center;
}
.loginCard h1 {
    text-align: center;
    margin-bottom: 25px;
   

}
.loginCard input {
    padding: 10px;
    margin-bottom: 30px;
    width: 100%;

}
.loginCard button {
    padding: 10px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 5px ;
    margin-top: 20px;
}
.loginCard button:hover{
    background-color: #000;
    color: #fff;
}
.loginCard p{
    text-align: center;
}
.signUpLink{
    font-size: 17px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
}

@media (max-width:768px){
    .loginCard{
        margin: 157px 0px;
        padding: 30px;
        width: 350px;
        box-shadow: 2px 3px 7px #000;
    }
}