.navbar-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #cccc;
    background-color: #000;
    color: white;
}
.nav-logo-div{
    width: 100px;
}
.nav-icon-div{
    width: 30%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media screen and (min-width: 769px) {
    .navbar-div{
        display: none;
    }
}

@media screen and (max-width: 425px) {
    .nav-icon-div{
        width: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}

.mobileHaderSection{
    background-color:#bdbdbd;
    background-image: url(../../assets/img/menuShapImg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .main_menu_list > li:not(:last-child) {
    margin-right: 30px;
  }
  .main_menu_list > li > a {
    display: block;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    padding: 15px 0px;
    text-transform: uppercase;
    transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  }
  .main_menu_list > li:hover > a,
  .main_menu_list > li.active > a {
    color: #c7a17a;
  }
  .main_menu_list .submenu {
    top: 100%;
    margin: 0px;
    border: none;
    font-size: 14px;
    min-width: 210px;
    padding: 20px 0px;
    border-radius: 8px;
    color: #7c7770;
    box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.15);
  }
  .main_menu_list .submenu > li > a {
    display: block;
    line-height: 1;
    padding: 15px 30px;
    color: #7c7770;
    transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  }
  .main_menu_list .submenu > li:hover > a,
  .main_menu_list .submenu > li.active > a {
    color: #c7a17a;
    background-color: rgba(199, 161, 122, 0.04);
  }
  .main_menu_list .submenu .dropdown > a {
    position: relative;
  }
  .main_menu_list .submenu .dropdown > a:after {
    float: right;
    font-size: 11px;
    margin-top: 2px;
    content: "";
    font-weight: 400;
    display: inline-block;
    font-family: "Font Awesome 5 Pro";
  }
  .main_menu_list .submenu .submenu {
    top: 0px;
    left: 100%;
    border-top-left-radius: 0px;
  }
  .main_menu_list li:hover > .submenu {
    display: block;
  }

.main_menu_list .submenu .dropdown > a {
  position: relative;
}
.main_menu_list .submenu .dropdown > a:after {
  float: right;
  font-size: 11px;
  margin-top: 2px;
  content: "";
  font-weight: 400;
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
}

.main_menu_list .submenu {
    top: 100%;
    margin: 0px;
    border: none;
    font-size: 14px;
    min-width: 210px;
    padding: 20px 0px;
    border-radius: 8px;
    color: #7c7770;
    box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.15);
  }