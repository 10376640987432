.blog_details .wrap_space {
    padding: 30px;
  }
  .blog_details .details_content {
    background-color: #ffffff;
  }
  .blog_details .details_image img {
    width: 100%;
    display: block;
  }

  .post_meta {
    font-size: 14px;
    color: #1b1b1b;
  }
  .post_meta li {
    margin-bottom: 20px;
  }
  .post_meta li:not(:last-child) {
    margin-right: 40px;
  }
  .post_meta a {
    display: block;
    color: #1b1b1b;
  }
  .post_meta a:hover {
    color: #c7a17a;
  }
  .post_meta i {
    margin-right: 5px;
  }
  .container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

  img:not([draggable]),
  embed,
  object,
  video {
    height: auto;
    max-width: 100%;
  }
@media (min-width: 576px) {
    .container,
    .container-sm {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container,
    .container-md,
    .container-sm {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1140px;
    }
  }
  @media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      max-width: 1320px;
    }
  }

  

  .blog_grid .post_meta {
    font-size: 13px;
    margin-top: 14px;
    padding-top: 16px;
    border-top: 2px solid #f6f6f6;
    justify-content: space-between;
  }
  .blog_grid .post_meta li {
    margin: 0px;
  }
  .blog_grid .post_meta a {
    color: #918f8d;
  }
  .blog_grid .post_meta a:hover {
    color: #c7a17a;
  }
  .blog_grid .post_meta i {
    margin-right: 5px;
    color: #c7a17a;
  }
  .blog_grid .item_image img {
    width: 100%;
    display: block;
    transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  }
  .blog_grid:hover .item_image img {
    transform: scale(1.08);
  }

  .blog_standard .item_image img {
    width: 100%;
    display: block;
    transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  }
  .blog_standard:hover .item_image img {
    transform: scale(1.08);
  }

  /* .comment_form .form_item input,
.comment_form .form_item textarea {
  border-radius: 0px;
  background-color: #f6f6f6;
} */



  @media screen and (max-width: 575px) {
    .post_meta li:not(:last-child) {
        margin-right: 25px;
      }

      .post_meta li {
        margin-bottom: 15px;
      }

      .sb_widget {
        padding: 20px;
      }
    
      .sb_widget_title {
        margin-bottom: 18px;
      }
  }
  

  @media screen and (max-width: 991px) {

  .blog_details .wrap_space {
    padding: 20px;
  }
  .sidebar_section {
    margin-top: 40px;
  }


  .details_section .details_title {
    font-size: 26px;
    margin-bottom: 15px;
  }

  .details_section .details_content p {
    margin-bottom: 15px;
  }
}

.sec_ptb_120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .details_content {
    margin-bottom: 60px;
  }
  .blog_details .details_image img {
    width: 100%;
    display: block;
  }
  
  .sb_widget {
    padding: 30px;
    background-color: #ffffff;
  }
  .sb_widget:not(:last-child) {
    margin-bottom: 30px;
  }
  .sb_widget .form_item input {
    border-radius: 0px;
    background-color: #f6f6f6;
  }
  .sb_widget .recent_post {
    background-color: #f6f6f6;
  }
  .sb_widget .recent_post:not(:last-child) {
    margin-bottom: 20px;
  }
  .sb_widget .recent_post .item_image {
    min-width: 90px;
    border-radius: 0px;
  }
  
  .sb_widget_title {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .form_item .form_icon {
    top: 50%;
    right: 25px;
    margin: 0px;
    padding: 0px;
    position: absolute;
    transform: translateY(-50%);
  }

  .recent_post {
    display: flex;
    align-items: center;
  }
  .recent_post:not(:last-child) {
    margin-bottom: 30px;
  }
  .recent_post .item_image {
    display: block;
    min-width: 75px;
    overflow: hidden;
    border-radius: 3px;
    position: relative;
    margin-right: 15px;
  }
  .recent_post .item_image img {
    width: 100%;
    display: block;
    transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  }
  .recent_post:hover .item_image img {
    transform: scale(1.08);
  }
  .recent_post .item_title {
    display: inline-block;
  }
  .recent_post .item_title a {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #1b1b1b;
  }
  .recent_post:hover .item_title a {
    color: #c7a17a;
    text-decoration: underline;
  }
  .recent_post .post_date {
    display: block;
    font-size: 12px;
    color: #c7a17a;
  }
  
  .text-white .recent_post .item_title a {
    color: #ffffff;
  }
  .text-white .recent_post:hover .item_title a {
    color: #c7a17a;
  }


  @media screen and (max-width: 1024px) {
    .sec_ptb_120 {
        padding-top: 80px;
        padding-bottom: 80px;
      }
    
  }
  

.details_section .area_title {
    line-height: 1;
    font-size: 24px;
    margin-bottom: 35px;
  }
  .details_section .details_title {
    line-height: 1;
    font-size: 30px;
    margin-bottom: 30px;
  }
  .details_section .area_title {
    line-height: 1;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .details_section .details_content p {
    /* margin-bottom: 25px; */
  }

  .details_section .btns_group {
    margin-bottom: 25px;
  }
  .details_section .btns_group li:not(:last-child) {
    margin-right: 15px;
  }
  .details_section hr {
    opacity: 1;
    margin: 30px 0;
    background-color: #f6f6f6;
  }