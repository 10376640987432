.breadcrumb_section {
  min-height: 400px;
  padding: 120px 0px;
}

.breadcrumb_icon {
  margin-left: 12px;
}
.bannerBackground {
  background-image: url(../img/sliderTwo.jpg);
}
.breadcrumb_section {
  display: flex;
  /* min-height: 500px; */
  padding: 150px 0px;
  position: relative;
  align-items: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  font-family: Arial, Helvetica, sans-serif;
}

.page_title {
  line-height: 1;
  font-size: 50px;
  margin-bottom: 10px;
}

.breadcrumb_nav li {
  font-size: 15px;
  font-weight: 700;
  position: relative;
  color: #c7a17a;
}
.breadcrumb_nav li:not(:last-child) {
  margin-right: 8px;
  padding-right: 8px;
}
.breadcrumb_nav li:after {
  top: 50%;
  right: -4px;
  content: "/";
  position: absolute;
  transform: translateY(-50%);
}
.breadcrumb_nav li:last-child:after {
  display: none;
}
.breadcrumb_nav a {
  color: #ffffff;
}
.breadcrumb_nav a:hover {
  color: #c7a17a;
}

.breadcrumb_icon_wrap {
  left: 0px;
  right: 0px;
  z-index: 10;
  bottom: -36px;
  position: absolute;
}

.breadcrumb_icon {
  z-index: 1;
  width: 72px;
  height: 72px;
  position: relative;
  align-items: center;
  display: inline-flex;
  justify-content: center;
}
.breadcrumb_icon .bg_shape {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  position: absolute;
  transform: rotate(45deg);
  background-color: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(179, 179, 179, 0.25);
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.myProfileCard {
  padding: 15px;
  justify-content: center;
  border: 1px solid black !important;
  margin: 20px;
  border-radius: 0px;
}

.sec_ptb_120 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.myProfileBtnDiv {
  display: flex;
  justify-content: space-between;
}

.profileCardLink {
  color: #000;
  font-weight: 600;
}
.addBtn {
  width: 53% !important;
}
.cardDivProfile {
  border-bottom: 1px solid #c7a17a;
  padding: 10px;
}

.ul_li,
.ul_li_right,
.ul_li_center {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.ul_li > li,
.ul_li_right > li,
.ul_li_center > li {
  float: left;
  list-style: none;
  display: inline-block;
}

.ul_li {
  justify-content: flex-start;
}

.editDiv{
  display: flex;
  justify-content: space-between;
}
.editlink{
  color: rgb(146, 146, 146);
}
.editlink:hover{
  color: #000;
}

.upper-reward{
  background-color: #d5d5d5;
   padding: 10px 0px;
}

.lower-reward{
  border: 1px solid grey;
   text-align: center;
    padding: 20px 0px;
     margin-top: 20px;
}

@media screen and (max-width: 1024px) {
  /* responsive global - start */
  .sec_ptb_120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
