
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}


.order-summery-card{
    /* background-color: #fefaf5; */
    padding: 15px;
    border-radius: 5px;
  }

  .order-card-header{
    display: flex ;
    justify-content: space-between;
  }  


  .delete-link {
    color: inherit;
    margin: 15px;
  }
  
  .delete-link:hover {
    color: rgba(216, 29, 12, 0.821); 
    cursor: pointer;
  }