.proudctDetailsCard{
    padding: 20px;
    margin: 20px 0px;
}
.productMainDiv h1{ 
font-family: Arial, Helvetica, sans-serif;
margin-bottom: 20px;
}
.priceDiv{
    margin-bottom: 20px;
}
.sizeDiv{
    margin: 20px 0px;
}
.addBtnDiv{
    margin: 20px 0px;
}
.productMainDiv{
    padding: 80px 0px;
}
.productMainDiv img{
    width: 50%;
   
}
.proudctImgDiv{
  display: flex;
  justify-content: center;
}

.radio-div{
    display:flex;
    gap:10px;
    border:1px solid #c7a17a;
    width:100%;
    align-items:center;
    padding:15px;
    margin-top: 10px;
  }

  .small-p{
    font-size: 12px;
  }

  .btn.btn_secondary {
    background-color: #1b1b1b;
    color: #fff;
    padding: 10px ;
  }

  .btn.btn_secondary:hover {
    /* background-color: #c7a17a;
    color: #fff; */
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
  }