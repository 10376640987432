

.breadcrumb_section {
    display: flex;
    min-height: 400px;
    padding: 150px 0px;
    width: 100%;
    margin-top: 115px;
    position: relative;
    align-items: flex-end;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .bannerBackground{
    background-image: url(../img/baner.jpg);
  }

  .page_title {
    line-height: 1;
    font-size: 60px;
    margin-bottom: 10px;
  }

  .breadcrumb_nav li {
    font-size: 15px;
    font-weight: 700;
    position: relative;
    color: #fff;
  }
  .breadcrumb_nav li:not(:last-child) {
    margin-right: 8px;
    padding-right: 8px;
  }
  .breadcrumb_nav li:after {
    top: 50%;
    right: -4px;
    content: "/";
    position: absolute;
    transform: translateY(-50%);
  }
  .breadcrumb_nav li:last-child:after {
    display: none;
  }
  .breadcrumb_nav a {
    color: #ffffff;
  }
  .breadcrumb_nav a:hover {
    color: #c7a17a;
  }


.breadcrumb_icon {
    z-index: 1;
    width: 72px;
    height: 72px;
    position: relative;
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }
  .breadcrumb_icon .bg_shape {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: -1;
    position: absolute;
    transform: rotate(45deg);
    background-color: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(179, 179, 179, 0.25);
  }

  .breadcrumb_icon_wrap {
    left: 0px;
    right: 0px;
    z-index: 10;
    bottom: -36px;
    position: absolute;
  }
  
  @media screen and (max-width: 991px) {

    .breadcrumb_section {
      margin-top: 0;
      min-height: 400px;
      padding: 120px 0px;
    }
  
    .page_title {
        font-size: 54px;
      }
 
  }

  .text-white {
    color: #fff !important;
  }
  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  @media screen and (max-width: 575px) {

    .page_title {
        font-size: 46px;
      }
  }
  @media screen and (max-width: 480px) {

    .page_title {
        font-size: 30px;
      }
  }

  @media screen and (max-width: 426px) {

    .breadcrumb_section {
      min-height: 100px;
      padding: 80px 0px;
      margin-top: 0px;
  }
  /* .lpoint-div {
   
    height: 0px !important;
  } */
  }

  @media screen and (max-width: 1199px) {

    .breadcrumb_icon {
        margin-left: 12px;
      }
  }


    

 

