.not-outer-div{
    width: 70%;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 5px;
    }
    .not-outer-div p{
        font-size: 12px;
    }
    .not-inner-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    }
    .not-img-div{
    width: 150px;
    
    }
    .not-img-div img{
        width: 100%;
        aspect-ratio: 1/1;
    }
    .not-p-div p:nth-child(odd){
        font-weight: bold;
    }

    .not-button-div{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .not-button{
        padding: 10px;
        background-color: #c7a17a;
        border: none;
        border-radius: 5px;
        color: white;

    }

    @media screen and (max-width: 768px) {
        .not-outer-div{
            width: 100%;
            }
    }