.open-header{
    text-align: center;
    font-size: 3vw;
    color: #000;
    margin-bottom: 40px;
}
.open-left{
    padding: 1% 0px 20% 5%;
}
.open-left h2{
    font-size: 1.7vw;
}
.open-left h3{
    font-size: 1.5vw;
}
.open-table{
    padding-bottom: 300px;
}
.open-table h3{
    line-height: 0px;
    font-size: 1.5vw;
}
.open-bg{
    background-size:cover;
    background-repeat:no-repeat;
    height:auto;
    padding-bottom: 80px;
}

.grid-div{
    display: flex;
    justify-content: space-between;
    width: 60%;
}
.card-div{
    border: 1px solid black;
}

@media (max-width:1024px){
   
}
@media (max-width:768px){
    .open-left h2{
        font-size: 2vw;
    }
    .open-left h3{
        font-size: 2vw;
    }
    .open-header{
        text-align: center;
        font-size: 3vw;
        color: #888179;
    }
}
@media (max-width:425px){
    .open-left h2{
        font-size: 4vw;
    }
    .open-left h3{
        font-size: 4vw;
    }
    .open-header{
        text-align: center;
        font-size: 6vw;
        color: #75522d;
    }
    .open-bg{
        background-size:cover;
        background-repeat:no-repeat;
        height:auto;
    }
}
.whatWeDoCard{
    height: 300px !important;
    display: flex !important;
    /* justify-content: center !important; */
    background-color: rgba(255, 255, 255, 0.4) !important;
    /* opacity: 0.4 !important; */
}
.whatWeDoCard button{
    border: 1px solid;
    margin: 20px;
    background-color: #fff;
    color: #000;
    padding: 15px;
}
.whatWeDoTitle{
    margin-top: 12%;
    font-size: 20px;
    text-align: center;
    /* padding-bottom: 20px; */
    font-weight: 700;
    color: #060606;
}   
.line {
    border-top: 1px solid white; /* Add top border */
    margin: 10px 0; /* Add margin for spacing */
    display: block; /* Ensure line is displayed as block */
    content: ""; /* Add empty content */
  }
