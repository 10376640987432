

.blog_standard {
    margin-bottom: 40px;
    background-color: #ffffff;
}
.blog_standard .item_image {
  display: block;
  overflow: hidden;
  position: relative;
}
.blogGridDiv{
  padding: 0px 20px;
}
.blog_standard .item_image img {
    width: 100%;
    display: block;
    transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  }

  .blog_standard:hover .item_image img {
    transform: scale(1.08);
  }

  .blog_standard .video_btn1 {
    top: 50%;
    left: 50%;
    width: 80px;
    margin: 0px;
    height: 80px;
    font-size: 16px;
    position: absolute;
    background-color: #ffffff;
    transform: translate(-50%, -50%);
  }

  .blog_standard .video_btn1:before,
.blog_standard .video_btn1:after {
  background-color: #ffffff;
}
.blog_standard .item_content {
  padding: 40px 30px;
}
.blog_standard .item_title {
  line-height: 1;
  margin-bottom: 30px;
}
.blog_standard .item_title a {
  font-size: 30px;
  color: #1b1b1b;
}
.blog_standard:hover .item_title a {
  color: #c7a17a;
}
.blog_standard p {
  margin-bottom: 30px;
}
.blog_standard .item_content {
    padding: 30px 20px;
  }


.recent_post {
    display: flex;
    align-items: center;
  }
  .recent_post:not(:last-child) {
    margin-bottom: 30px;
  }
  .recent_post .item_image {
    display: block;
    min-width: 75px;
    overflow: hidden;
    border-radius: 3px;
    position: relative;
    margin-right: 15px;
  }
  .recent_post .item_image img {
    width: 100%;
    display: block;
    transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  }
  .recent_post:hover .item_image img {
    transform: scale(1.08);
  }
  .recent_post .item_title {
    display: inline-block;
  }
  .recent_post .item_title a {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #1b1b1b;
  }
  .recent_post:hover .item_title a {
    color: #c7a17a;
    text-decoration: underline;
  }
  .recent_post .post_date {
    display: block;
    font-size: 12px;
    color: #c7a17a;
  }
  
  .text-white .recent_post .item_title a {
    color: #ffffff;
  }
  .text-white .recent_post:hover .item_title a {
    color: #c7a17a;
  }

  .post_meta {
    font-size: 14px;
    color: #1b1b1b;
  }
  .post_meta li {
    margin-bottom: 20px;
  }
  .post_meta li:not(:last-child) {
    margin-right: 40px;
  }
  .post_meta a {
    display: block;
    color: #1b1b1b;
  }
  .post_meta a:hover {
    color: #c7a17a;
  }
  .post_meta i {
    margin-right: 5px;
  }
  
  .blog_grid {
    padding: 20px;
    margin-top: 30px;
    position: relative;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0px 7px 15px 0px rgba(225, 225, 225, 0.3);
  }
  .blog_grid .post_date {
    top: 35px;
    left: 35px;
    z-index: 1;
    line-height: 1;
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
    padding: 15px 16px;
    position: absolute;
    border-radius: 3px;
    font-family: "Oswald", sans-serif;
  }
  .blog_grid .item_image {
    display: block;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
  }
  .blog_grid .item_image img {
    width: 100%;
    display: block;
    transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  }
  .blog_grid:hover .item_image img {
    transform: scale(1.08);
  }
  .blog_grid .item_content {
    padding: 25px 5px 5px;
  }
  .blog_grid .item_title {
    margin-bottom: 13px;
    display: inline-block;
  }
  .blog_grid .item_title a {
    display: block;
    font-size: 20px;
    color: #1b1b1b;
  }
  .blog_grid:hover .item_title a {
    color: #c7a17a;
  }
  .blog_grid p {
    margin-bottom: 10px;
  }
  .blog_grid .btn_text {
    color: #c7a17a;
  }
  .blog_grid .post_meta {
    font-size: 13px;
    margin-top: 14px;
    padding-top: 16px;
    border-top: 2px solid #f6f6f6;
    justify-content: space-between;
  }
  .blog_grid .post_meta li {
    margin: 0px;
  }
  .blog_grid .post_meta a {
    color: #918f8d;
  }
  .blog_grid .post_meta a:hover {
    color: #c7a17a;
  }
  .blog_grid .post_meta i {
    margin-right: 5px;
    color: #c7a17a;
  }

  .pagination_nav {
    margin: -3px;
    margin-top: 77px;
  }
  .pagination_nav li {
    padding: 3px;
  }
  .pagination_nav a {
    height: 50px;
    min-width: 50px;
    display: flex;
    font-size: 15px;
    border-radius: 45px;
    align-items: center;
    color: #7c7770;
    justify-content: center;
    background-color: #ffffff;
  }
  .pagination_nav li:hover a,
  .pagination_nav li.active a {
    color: #ffffff;
    background-color: #c7a17a;
  }

  .sidebar_section {
    margin-top: 40px;
  }

  .sec_ptb_120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .sb_widget {
    padding: 30px;
    background-color: #ffffff;
  }
  .sb_widget:not(:last-child) {
    margin-bottom: 30px;
  }
  .sb_widget .form_item input {
    border-radius: 0px;
    background-color: #f6f6f6;
  }
  .sb_widget .recent_post {
    background-color: #f6f6f6;
  }
  .sb_widget .recent_post:not(:last-child) {
    margin-bottom: 20px;
  }
  .sb_widget .recent_post .item_image {
    min-width: 90px;
    border-radius: 0px;
  }
  
  .sb_widget_title {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .recent_post .post_date {
    display: block;
    font-size: 12px;
    color: #c7a17a;
  }

  .sb_category li:not(:last-child) {
    margin-bottom: 15px;
  }
  .sb_category a {
    display: flex;
    line-height: 1;
    align-items: center;
    padding: 17.5px 20px;
    color: #7c7770;
    background-color: #f6f6f6;
    justify-content: space-between;
  }
  .sb_category a:hover {
    color: #ffffff;
    background-color: #c7a17a;
  }

  .form_item {
    position: relative;
    margin-bottom: 30px;
  }
  .form-item-b{
    border: 1px solid #adb5bd !important;
    border-radius: 5px;
  }
  .form_item textarea,
  .form_item input {
    width: 100%;
    outline: none;
    display: block;
    box-shadow: none;
    border-radius: 5px;
    border: 1px solid #f5f5f5;
    transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  }
  .form_item textarea:focus,
  .form_item input:focus {
    outline: none;
    box-shadow: none;
    border-color: #c7a17a;
  }
  .form_item input {
    height: 56px;
    padding: 0px 25px;
  }
  .form_item textarea {
    min-height: 160px;
    padding: 20px 25px;
    border: 1px solid #ccc;
  }
  .form_item .form_icon {
    top: 50%;
    right: 25px;
    margin: 0px;
    padding: 0px;
    position: absolute;
    transform: translateY(-50%);
  }
  /* .form_icon:hover {
    visibility: hidden;
  } */

  .form_item .form_field_title {
    line-height: 1;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 15px;
    color: #1b1b1b;
    font-family: "Roboto", sans-serif;
  }
  .form_item .form_field_title sup {
    top: -2px;
  }

  .ul_li,
.ul_li_right,
.ul_li_center {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.ul_li > li,
.ul_li_right > li,
.ul_li_center > li {
  float: left;
  list-style: none;
  display: inline-block;
}
.ul_li_right {
    justify-content: flex-end;
  }

  .ul_li {
    justify-content: flex-start;
  }
  
  .ul_li_center {
    justify-content: center;
  }
  
  .ul_li_right {
    justify-content: flex-end;
  }
  
  .ul_li_block {
    margin: 0px;
    padding: 0px;
    display: block;
  }
  .ul_li_block > li {
    display: block;
    list-style: none;
  }

  .container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.container {
    max-width: 1200px;
  }

@media (min-width: 768px) {
    .container,
    .container-md,
    .container-sm {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1140px;
    }
  }
  @media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      max-width: 1320px;
    }
  }

  .navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.toast-container {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none;
  }

  .toast-container > :not(:last-child) {
    margin-bottom: 0.75rem;
  }

 
  @media screen and (max-width: 575px) {
    /* responsive global - start */
    .sb_widget {
      padding: 20px;
    }
  
    .sb_widget_title {
      margin-bottom: 18px;
    }
    .container,
    .container-sm {
      max-width: 540px;
    }

    .blog_standard .item_content {
        padding: 30px 20px;
      }
    
      .blog_standard .item_title {
        margin-bottom: 15px;
      }
    
      .blog_standard .item_title a {
        font-size: 22px;
      }
    
      .blog_standard p {
        margin-bottom: 15px;
      }
    
      .blog_standard .video_btn1 {
        width: 60px;
        height: 60px;
        font-size: 14px;
      }
       
    .form_item {
        margin-bottom: 15px;
      }

  }



  @media screen and (max-width: 1024px) {
    /* responsive global - start */
    .sec_ptb_120 {
      padding-top: 80px;
      padding-bottom: 80px;
    }
}


