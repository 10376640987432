.feat-header{
    text-align: center;
    font-size: 3vw;
    color: #0c0601;
}
.feat-flex{
    display: flex;
    width: 100%;
}
.feat-left, .feat-right{
    flex: 1;
    padding: 5%;
}
.feat-left img{
    width: 100%;
}
.feat-right p{
    text-align: justify;
    line-height: 30px;
}
.feature-div img{
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 0;
    transition: border-radius linear 0.5s;
}
.col-img{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;
}
.col-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.feature-div img:hover{
    border-radius: 50%;
}

@media (max-width:1024px){
   
}
@media (max-width:768px){
   
}
@media (max-width:425px){
    .feat-flex{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .feat-header{
        text-align: center;
        font-size: 6vw;
        color: #75522d;
    }
}