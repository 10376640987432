.bg_default_gray {
  /* background-color: #f6f6f6; */
}

.container-border {
  border: 2px solid #ccc;
  padding: 20px;
  position: relative;
  border-radius: 5px;
}

.checkout-page-header {
  display: flex;
  justify-content: space-between;
}

.checkout-page-header img {
  width: 100px;
}
.checkout-page-header a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-page-header a :hover {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.title {
  background-color: #f6f6f6;
  position: absolute;
  top: -10px;
  left: 20px;
  padding: 0 10px;
}

.container-buttons {
  display: flex;
  justify-content: space-between;
}

.container-buttons button {
  border: none;
  padding: 8px 80px;
  background-color: #ffc400;
  border-radius: 5px;
  cursor: pointer;
}
.card-header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.billing-btn-div {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
.billing-btn-div .wid {
  width: 45%;
}

.order-summery-card {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
}
.invoiceBtn {
  padding: 3px 30px !important;
}
.order-summery-name-price {
  padding: 15px;
}

.order-summery-name-price p {
  font-size: small;
}
.order-card-header {
  display: flex;
  justify-content: space-between;
}

.btn_text {
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
  color: #7c7770;
  display: inline-block;
}
.btn_text span {
  position: relative;
  display: inline-block;
}
.btn_text span:before {
  width: 0px;
  left: auto;
  right: 0px;
  bottom: 0px;
  height: 1px;
  content: "";
  position: absolute;
  transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  background-color: #fff;
}
.btn_text:hover span:before {
  left: 0px;
  width: 100%;
  right: auto;
}
.btn_text i {
  margin-left: 2px;
  transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}
.btn_text:hover {
  color: #c7a17a;
}
.btn_text:hover i {
  transform: translateX(5px);
}
.order-product-image {
  width: 80px;
  height: 80px;
  border-radius: 3px;
}
.order-summery-name-price {
  padding: 15px;
}

.order-summery-name-price p {
  font-size: small;
}
.secure-checkout-p {
  justify-content: center;
  display: flex;
  align-items: center;
  color: #7c7770;
}
