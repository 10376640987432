

.txt-start{
    text-align: start;
  }

  .td-bold{
    padding-top:20px;
    border-bottom:1px solid black;
    border-top:1px solid black;
    font-size:22px;
  }
  .txt-end{
    text-align: end;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
  }