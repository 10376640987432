.about-flex{
    display: flex;
    justify-content: space-between;
}
.about-left{
    padding: 5%;
    flex: 2;
}
.about-left p{
    color: rgb(92, 91, 91);
    line-height: 40px;
}
.about-right{
    padding: 5%;
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-right img{
    width: 85%;
}
.about-header{
    font-size: 3vw;
    color: #000;
}
.pd-tp-50{
    padding-top: 50px;
}
.section_title .big_title {
    font-size: 45px;
    line-height: 1.2;
    margin-bottom: 0;
}
.about-img{
    aspect-ratio: 1/1;
    border-radius: 50%;
    object-fit: cover;
    animation: rotateimg 20s linear infinite;
}
.about-main-div{
    background-size:100%;
    background-repeat:no-repeat;
}

@keyframes rotateimg{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

@media (max-width:1024px){
    .about-img{
        margin-top: 50px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        object-fit: cover;
        animation: rotateimg 20s linear infinite;
    }
}
@media (max-width:768px){
    .about-main-div{
        background-size:130%;
        background-repeat:no-repeat;
    }
}
@media (max-width:425px){
    .about-main-div{
        background-size:400%;
        background-repeat:no-repeat;
    }
    .about-flex{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .about-header{
        font-size: 6vw;
        color: #75522d;
        margin-top: 50px;
    }
}

@media (max-width:425px){
    .about-main-div{
        background-size:430%;
        background-repeat:no-repeat;
    }
}