.btn_text {
    line-height: 1;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    display: inline-block;
  }
  .btn_text span {
    position: relative;
    display: inline-block;
  }
  .btn_text span:before {
    width: 0px;
    left: auto;
    right: 0px;
    bottom: 0px;
    height: 1px;
    content: "";
    position: absolute;
    transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    background-color: #000000;
  }
  .btn_text:hover span:before {
    left: 0px;
    width: 100%;
    right: auto;
  }
  .css-1d0712g{
    overflow-y: hidden !important;
  }
  .btn_text i {
    margin-left: 2px;
    transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  }
  .react-multiple-carousel__arrow{
    z-index: 1;
  }
  .loyaltyMainDiv{
    overflow-y: scroll;
    height: 100%;
    width: 100%;
  }
  .btn_text:hover {
    color: #000000;
  }
  .btn_text:hover i {
    transform: translateX(5px);
  }

  .order-product-image{
    max-width: 80px !important;
    height: 80px;
    border-radius: 3px;
  }
  .order-summery-name-price{
    padding: 15px;
  }
  
  .order-summery-name-price p {
    font-size: small;
  }
  .quantity-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 2px;
    width: 100px;
    border-radius: 5px;
  }
  .quantity-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 0 5px;
  }
  
  .quantity-button:hover {
    background-color: #f0f0f0;
    /* color: reds;  */
    color: black;
  
  }
  .shopping-cart-btn-div{
    display:flex;
    flex-direction:column;
    gap:20px;
  }

  .invoiceBtn{
    padding: 3px 30px !important;
  }
  .invoiceBtn:hover{
    background-color: white !important;
    color: black !important;
    border: 1px solid black !important;
  }
  .profile-img-file-input {
    display: none;
  }

  
.custom-button {
  /* background-color: #F8C6C0; */
  background-color: black;
  border: none;
  width: 30%;
  /* border-radius: 20px; */
  color: #fff;
  padding: 10px 20px;
}

  .block-contact-form .block-title {
    margin-bottom: 30px;
  }

  .contact-us-form{
    margin: 20px 0 !important;

    .store-loacte-select {
      width: 100%;
      /* background: transparent; */
      color: black;
      font-size: 14px;
      padding: 10px 20px;
      font-weight: 500;
      border: 1px solid black;
    }

  }
  .closeDiv{
    font-size: 29px;
    cursor: pointer;
  }
  .headerLink{
    display: block; 
    color: #000000 !important;
    margin-bottom: 10px;
  }
  .feature_primary .item_title {
    line-height: 1;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 13px;
}
.howItWorkIcon {
  font-size: 50px;
  margin-bottom: 10px;
}
.subscribeDiv {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}
.orderBtn{

    border: 1px solid;
    background: #000;
    color: #fff;
    padding: 10px;
    border-radius: 7px;
    font-size: 16px;
}
.categoryUl{
  display: flex;
  gap: 20px;
  justify-content: center;
}
.categoryUl a{
  color: #000;
  border-bottom: 1px solid #000;
}
.bansContainer{
  padding-bottom: 80px;
}
.bansContainer .nav-link{
  color: #000 !important;
}
.beansCard{
  padding: 20px;
  margin: 20px 0px;
border: 1px solid #000;
}
.mainDivHowItWork {
  padding: 80px 0px;
}
.homeLinks{
  background-color: #fff;
    color: #fff;
    padding: 15px ;
    /* width: 120px; */
    text-align: center;
    font-weight: 600 !important;
    font-size: 16px;
}
.dropDown2{
  background: #f1c232;
  padding: 15px ;
}
.locationDiv{
  margin-top: 30px;
  background: #f1c232;
  padding: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.locationDiv:hover{
  background-color: #000;
  color: #fff;
}
  .avatar-title {
    margin-top: 20px;
    margin-left: 20px;
    align-items: center;
    background-color: #ffffff;
    color: #ffffff;
    display: flex;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
  .rounded-circle {
    border-radius: 50% !important;
  }
  

  .tailSpinWrapper:hover {
       color: #000000; /* Change this to your desired color */
  }

  .maindivViewBtn {
    display: flex;
    justify-content: space-between;
  }

  .loyalty-table{
    width: 100%;
  }
  .loyalty-table td, .loyalty-table th{
    text-align: center;
    padding: 20px 0px;
  }
  .green{
    background-color: rgb(225, 252, 225);
    font-weight: bold;
    /* color: white; */
    width: 100px;
    margin: auto;
    border-radius: 5px;
  }
  .red{
    background-color: rgb(250, 168, 168);
    /* color: white; */
    font-weight: bold;
    width: 100px;
    margin: auto;
    border-radius: 5px;
  }
  .lpoint-div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }
  .close-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
  .mainDivHader{
    display: none;
  }
  }

  @media screen and (max-width: 426px) {
    .loyalty-table td, .loyalty-table th {
      text-align: center;
      padding: 20px 0px;
      min-width: 150px;
  }
  }