.open-header{
    text-align: center;
    font-size: 3vw;
    color: #75522d;
}
.open-left{
    padding: 1% 0px 20% 5%;
}
.blogBg {
    background-image: url(./../img/blogBg.0773bceed9ac2b312423.png)
}
.open-left h2{
    font-size: 1.7vw;
}
.open-left h3{
    font-size: 1.5vw;
}
.open-table{
    padding-bottom: 300px;
}
.open-table h3{
    line-height: 0px;
    font-size: 1.5vw;
}
.open-bg{
    background-size:100%;
    background-repeat:no-repeat;
    height:auto;
}

.inner-div{
    width: 60%;
    margin: auto;
    text-align: center;
}

.border-cc{
    border: 1px solid #cccc;
    padding-bottom: 80px;
}

@media (max-width:1024px){
   
}
@media (max-width:768px){
    .open-left h2{
        font-size: 2vw;
    }
    .open-left h3{
        font-size: 2vw;
    }
    .open-header{
        text-align: center;
        font-size: 3vw;
        color: #75522d;
    }
}
@media (max-width:425px){
    .open-left h2{
        font-size: 4vw;
    }
    .open-left h3{
        font-size: 4vw;
    }
    .open-header{
        text-align: center;
        font-size: 6vw;
        color: #75522d;
    }
    .open-bg{
        background-size:150%;
        background-repeat:no-repeat;
        height:auto;
    }
}