.subs-div{
    text-align: center;
    padding: 30px 0px;
    display: flex;
    justify-content: space-around;
    color: #fff;
    /* margin-left: 5%;
    margin-right: 5%; */
    width: 100%;
    /* background: linear-gradient(to bottom, #c7a17a 0%, #CCCC 100%); */
    /* background-color:rgb(53, 52, 52); */
    transition: background-color 0.5s ease;
}

.light {
    background-color: rgb(53, 52, 52); /* Light background color */
  }
  
  .dark {
    background-color: #030303; /* Dark background color */
  }

.subs-inner{
display: flex;
gap: 25px;
}

.subs-input{
  display: flex;
  justify-content: center;
/* gap: 25px; */
flex-direction: column;
}


/* .subs-inner input{
   width:100%
} */
.subs-inner button{
    /* padding: 5px; */
    /* width: 200px; */
border-radius: 10PX;
    background-color: #fff;
}

@media (max-width:435px){
  .subs-div{
    flex-direction: column;
    width: 100%;
    


  }
  .subs-inner{
    display: flex;
    justify-content: center;
    }

    .subs-input{
      display: flex;
      justify-content: center;
      margin-left: 30%;
    }
    
    #subscribe-input{
      font-size: 18px !important;
    }
}



@media (max-width:250px){
  .subs-div{
    flex-direction: column;
    width: 100%;
    


  }
  .subs-inner{
    display: flex;
    justify-content: center;
    }

    .subs-input{
      display: flex;
      justify-content: center;
      margin-left: 20%;
    }
    
    #subscribe-input{
      font-size: 15px !important;
    }
}