.faqContainer{
    padding: 80px 0px;
}
.dropdowns{
    width: 50%;
    margin: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
    .dropdown-qst{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: bold;
    }
    .dropdown-qst:hover{
        cursor: pointer;
    }
    .faq-inner-div{
        width: 55%;
    }
    .dropdown-ans{
    color: grey;
    padding: 10px;
    width: 100%;
    overflow: hidden;
    animation: hello 1s;
    }
    
    @keyframes hello{
        0%{
            height: 0px;
        }
        100%{
            height: 100px;
        }
    }
    
    @media screen and (max-width: 768px) {
        .dropdowns{
            width: 100%;
            }
    }
    @media screen and (max-width: 425px) {
        .faq-inner-div{
            width: 100%;
        }
        .faqContainer {
            padding: 0px 0px;
        }
    }