.bradCrumDiv{
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    margin-top: 78px;
    /* background-size: contain; */
}
.bradmCrumTitleSection{
    padding-top: 150px;
    z-index: 1;
    position: relative;
}
.product-group-card {
    background-color: #fff;

    justify-content: center;

    position: relative;
    /* text-align: center; */
    transition: .2s ease-in-out;
  
}
.product-group-card::after {
    /* background: linear-gradient(180deg, rgb(143 143 143 / 54%), rgba(0, 0, 0, .77)); */
    border-radius: 7px;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    transition: all .5s;
    width: 100%;
    /* z-index: 2; */
}
.bradCrumTitle{
    font-size: 30px;
    color: #000;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 20px;
}
.product-filter-sort-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.productListingCard{
    padding: 20px;
    margin: 20px 0px;
    /* box-shadow: 1px 1px 5px gray; */
    border: none !important;
}
.productListingTitle{
    color: #000;
    text-transform: uppercase;
    text-align: center;
}
.producPrice{
    text-align: center;
    color: #12b200;
}
.productImgListing{
    padding: 20px;
    width: 100%;
}
.shopNowBtn{
    padding: 10px;
    border-radius: 5px;
    color: #000;
    border: 1px solid #ccc;
    text-align: center;
    margin-top: 15px;

}
.shopNowBtn:hover{
    background-color: #000;
    color: #fff;
}
.productListinContainer{
    padding: 80px 0px;
}
.bradCrunLink{
    color: #000;
    font-size: 20px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;

}
.bradCrumDiv span {
    color: #000;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;

}

