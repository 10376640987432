.scrolable {
    height: 45vh;
    overflow-y: scroll;
    scrollbar-color: #0000;
    scrollbar-width: thin;
}
.increemntBtn{
    background: #fff;
    border-radius: 5px;
    margin: 10px;
    border: none;
}
.mainDivCounter{
    display: flex;
    padding: 10px;
    background: #fff;
    gap: 10px;
    align-items: center;
    font-size: 15px;
  }
.cartDiv{
    display: flex;
    align-items: center;
    gap: 10px;
}
.cartDiv img{
    width: 70px;
}
.scrolable .card{
    padding: 20px;
}
.cartTitleDiv{
    padding-bottom: 20px;
}
.totalDiv{
    display: flex;
    justify-content: space-between;
    margin: 30px 0px;
}

.quantity-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 0 5px;
  }
  
  .quantity-button:hover {
    background-color: #f0f0f0;
    /* color: reds;  */
    color: black;
  
  }

  .ankers-1{
    color: #fff;
    
    }
    .ankers-1:hover{
      color: #000 !important;
      cursor: pointer;

      /* border: 1px solid #000; */
      }

      .cartsidebar-btn{
        color: #fff;

        /* color: #000 !important; */
      cursor: pointer !important;
      border: 1px solid #fff !important;
      }

      .cartsidebar-btn:hover{

        color: #000 !important;
      cursor: pointer !important;
      border: 1px solid #000 !important;
      }
  